import React from 'react'
import styled from '@emotion/styled'
import ServicesPageBackground from './ServicesPageBackground'
import ServicesPageBackgroundLow from './ServicesPageBackgroundLow'
import IconAnnounce from '../../../static/images/announce_icon.svg'
import IconService from '../../../static/images/info-service-icon.svg'
import IconHello from '../../../static/images/info-service-hello.svg'
import { Link } from 'gatsby'

// Page Banner
const PageBannerContainer = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: auto;
    height: auto;
  }
`
const TitleHeader = styled.h1`
	word-break: keep-all;
	font-family: "Apple SD Gothic Neo";
	align-items: center;
  font-size: 2.5em;
  text-align: center;
  font-weight: 500;
	line-height: 2.5rem;
	color: ${props => props.theme.colors.text};
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		font-size: 2em;
		line-height: 2.25rem;
	}
`
const TitleDescriptionContainer = styled.div` 
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`
const TitleDescription = styled.h5`
	word-break: keep-all;
  color: ${props => props.theme.colors.text};
  font-family: "Apple SD Gothic Neo";
  align-items: center;
  font-size: ${props => props.fontSize ? props.fontSize + `em` : `1.6em`};
  font-weight: 300;
	line-height: 1.2rem;
	padding: 0 0.25rem 0.5rem 0.25rem;
	border-bottom: 2px solid ${props => props.theme.colors.green};
	margin-bottom: 3.5rem;
	// line-height: ${props => props.fontSize ? props.fontSize + .2 + `rem` : `1.2rem`};
  // margin-top: ${props => props.marginTop ? props.marginTop + `rem` : `initial`};
	// margin-left: ${props => props.marginLeft ? props.marginLeft + `rem` : `initial`};
	// margin-right: ${props => props.marginRight ? props.marginRight + `rem` : `initial`};
	// margin-bottom: ${props => props.marginBottom ? props.marginBottom + `rem` : `initial`};
`
const BoldfaceText = styled.span`
  font-weight: ${props => props.fontWeight ? props.fontWeight : `700`};
`

// Banner Section
const InfoBannerSection = styled.section`
  display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  background: ${`linear-gradient(315deg, #DBF1D5 0%, #FFF3E9 100%)`};
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		padding: 3.5rem 0;
	}
`
const InfoBannerSectionDetailsContainer = styled.div` 
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-top: 0.5rem;
	padding-bottom: ${props => props.paddingBottom ? props.paddingBottom + `rem` : `initial`};
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		padding-bottom: ${props => props.paddingBottom ? props.paddingBottom - 0.5 + `rem` : `initial`};
	}
`
const InfoBannerSectionTitle = styled.h5`
	word-break: keep-all;
  color: ${props => props.theme.colors[props.fontColor]};
  font-family: "Apple SD Gothic Neo";
  align-items: center;
  font-size: ${props => props.fontSize ? props.fontSize + `em` : `1.6em`};
  font-weight: 300;
	line-height: ${props => props.fontSize ? props.fontSize + 0.5 + `rem` : `1.2rem`};
	padding: 0 0.75rem;
	margin-top: 4px;
`
const InfoBannerSectionDesc = styled.span`
	word-break: keep-all;
  align-items: center;
	max-width: 50rem;
  color: ${props => props.theme.colors.text};
  font-family: "Apple SD Gothic Neo";
  font-size: 1.4em;
	font-weight: ${props => props.fontWeight ? props.fontWeight : `300`};
	line-height: 2rem;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		max-width: 75%;
	}
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		max-width: 90%;
		font-size: 1.2em;
		line-height: 1.75rem;
	}
`
const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		padding-bottom: 0;
	}
`
const ButtonPrimary = styled.button`
  z-index: 2;
  width: 9.25rem;
  height: 2.5rem;
  padding: 0 1rem;
	margin: 0 1rem;
  color: ${props => props.theme.colors.green};
	font-weight: 600;
  border-radius: 1.5rem;
  border: 2px solid ${props => props.theme.colors.green};
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		margin: 0 0.5rem;
	}
  /* For iPhone 5/SE */
  @media screen and (max-width: 350px) {
    width: 7rem;
    height: 2rem;
    padding: 0 0.5rem;
  }
  &:hover {
    background-color: rgb(67 182 73 / 10%);
  }
  transition: background-color 0.3s ease;
  cursor: pointer;
  
`
const ButtonSecondary = styled.button`
  z-index: 2;
  width: 9.25rem;
  height: 2.5rem;
  padding: 0 1rem;
  color: ${props => props.theme.colors.orange};
	font-weight: 600;
  border-radius: 1.5rem;
  border: 2px solid ${props => props.theme.colors.orange};
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		margin: 0 0.5rem;
	}
  /* For iPhone 5/SE */
  @media screen and (max-width: 350px) {
    width: 7rem;
    height: 2rem;
    padding: 0 0.75rem;
  }
  &:hover {
    background-color: rgb(255 193 81 / 30%);
  }
  transition: background-color 0.3s ease;
  cursor: pointer;
`
// Sections
const SectionContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.padding}rem 0;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding: ${props => props.padding - 1}rem 0;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
    padding: ${props => props.padding - 2}rem 0;
  }
`
const SectionContentContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
  align-items: center;
	max-width: 60rem;
  width: 100%;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
    width: 90%;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		width: 100%;
  }
`
const SectionContentTitle = styled.h2`
	word-break: keep-all;
	font-family: "Apple SD Gothic Neo";
	font-size: 2.25em;
	line-height: 3rem;
	padding: 1rem 0;
	text-align: center;
`
const SectionContentRow = styled.div`
	display: flex;
	flex: 100%;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 20rem;
	padding-top: 1rem;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		max-width: 50rem;
	}
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		min-width: initial;
		padding-top: initial;
	}
`
// Table
const ServiceScheduleTable = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 2rem;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		width: 95%;
		margin-bottom: 1rem;
	}
`
const ServiceScheduleHeader = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 2rem;
	background: rgba(67,182,73,0.1);
`
const ServiceScheduleRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-bottom: 1px solid #AEB7BF;
`
const ServiceScheduleCell = styled.div`
	display: flex;
	flex: ${props => props.flex};
	width: 100%;
	min-height: ${props => props.minHeight ? props.minHeight : `5`}rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	word-break: keep-all;
	font-size: 1.2em;
	font-weight: ${props => props.fontWeight ? props.fontWeight : `initial`};
	font-family: "Apple SD Gothic Neo";
	padding: 1rem 0;
	color: ${props => props.fontColor ? props.theme.colors[props.fontColor] : props.theme.colors.text};
	line-height: 1.6rem;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		font-size: 1em;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		font-size: 0.9em;
  }
`
const MobileLineBreak = styled.br`
	&:after {
  }
	display: none;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
    display: initial;
  }
`
const MapLink = styled.a`
	font-family: "Apple SD Gothic Neo";
	color: ${props => props.fontColor ? props.theme.colors[props.fontColor] : props.theme.colors.text};
	text-decoration: none;
	@media screen and (max-width: ${props => props.theme.responsive.large}) {
		font-size: 1em;
  }
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
		font-size: 0.9em;
  }
`
const TagLinkExternal = styled.a`
	font-family: "Apple SD Gothic Neo";
	font-weight: ${props => props.fontWeight ? props.fontWeight : `initial`};
	color: ${props => props.fontColor ? props.theme.colors[props.fontColor] : props.theme.colors.text};
	text-decoration: none;
`
const TagLink = styled(Link)`
	font-family: "Apple SD Gothic Neo";
	font-weight: ${props => props.fontWeight ? props.fontWeight : `initial`};
	color: ${props => props.fontColor ? props.theme.colors[props.fontColor] : props.theme.colors.text};
	text-decoration: none;
`

const ServicesPage = () => {
	return (
		<>
			<ServicesPageBackground>
				<PageBannerContainer>
					<TitleContainer>
						<TitleDescriptionContainer>
							<TitleDescription>
								{`예배 안내`}
							</TitleDescription>
						</TitleDescriptionContainer>
						<TitleHeader>
							<BoldfaceText fontWeight={600}>{`JOYFUL CHURCH`}</BoldfaceText>{`에`} <MobileLineBreak />{` 오신걸 환영합니다!`}
						</TitleHeader>
					</TitleContainer>
				</PageBannerContainer>
			</ServicesPageBackground>
			<InfoBannerSection padding={5}>
				<InfoBannerSectionDetailsContainer paddingBottom={2.5}>
					<IconAnnounce />
					<InfoBannerSectionTitle fontColor={`green`}>{`공지사항`}</InfoBannerSectionTitle>
					<IconAnnounce />
				</InfoBannerSectionDetailsContainer>
				<InfoBannerSectionDetailsContainer paddingBottom={2}>
					<InfoBannerSectionDesc>
						{`주일예배를 현장과 온라인으로 참여하실수 있습니다.`}
					</InfoBannerSectionDesc>

				</InfoBannerSectionDetailsContainer>
				<ButtonsContainer>
					<TagLink fontColor={`green`} href="/online-service">
						<ButtonPrimary>
							{`온라인 예배`}
						</ButtonPrimary>
					</TagLink>
				</ButtonsContainer>
			</InfoBannerSection>
			
			<SectionContainer padding={5}>
				<SectionContentContainer>
					<SectionContentRow>
						<IconService />
					</SectionContentRow>
					<SectionContentRow>
						<SectionContentTitle>{`예배 시간과 장소`}</SectionContentTitle>
					</SectionContentRow>
					<SectionContentRow>
						<InfoBannerSectionDetailsContainer paddingBottom={0}>
							<InfoBannerSectionDesc>
								{`안내: 교육사역 및 예배 시간과 장소 안내는 `}<TagLink fontColor={`green`} fontWeight={500} to={`/info/calendar#recurring`}>{`교회일정 페이지`}</TagLink>{`에서 확인 부탁드립니다.`}
							</InfoBannerSectionDesc>
						</InfoBannerSectionDetailsContainer>
					</SectionContentRow>
					<SectionContentRow>
						<ServiceScheduleTable>
							<ServiceScheduleHeader>
								<ServiceScheduleCell flex={2} fontWeight={700} minHeight={3.5}>{`예배`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={700} minHeight={3.5}>{`시간`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={700} minHeight={3.5}>{`장소`}</ServiceScheduleCell>
							</ServiceScheduleHeader>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`주일예배`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`주일 2PM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500} fontColor={`green`}>
									<MapLink fontColor={`green`} href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">
										{`New Life Church (Dublin Campus)`}<br/>{` 6444 Sierra Ct, Dublin, CA 94568`}
									</MapLink>
								</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`새벽예배`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`화-금요일 5:30AM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500} fontColor={`green`}>
									<MapLink fontColor={`green`} href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">
										{`New Life Church (Dublin Campus) - Roots Room`}<br/>{` 6444 Sierra Ct, Dublin, CA 94568`}
									</MapLink>
								</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`청년부 예배`}<MobileLineBreak />{` (카이로스)`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`금요일 8 PM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500} fontColor={`green`}>
									<MapLink fontColor={`green`} href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">
										{`New Life Church (Dublin Campus)`}<br/>{` 6444 Sierra Ct, Dublin, CA 94568`}
									</MapLink>
								</ServiceScheduleCell>
							</ServiceScheduleRow>
							<ServiceScheduleRow>
								<ServiceScheduleCell flex={2} fontWeight={700}>{`금요예배`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3}>{`매달 마지막 금 7:30PM`}</ServiceScheduleCell>
								<ServiceScheduleCell flex={3} fontWeight={500} fontColor={`green`}>
									<MapLink fontColor={`green`} href="https://goo.gl/maps/GnzrcuFcZTTrj4b96" target="_blank">
										{`New Life Church (Dublin Campus)`}<br/>{` 6444 Sierra Ct, Dublin, CA 94568`}
									</MapLink>
								</ServiceScheduleCell>
							</ServiceScheduleRow>
						</ServiceScheduleTable>
					</SectionContentRow>
				</SectionContentContainer>
			</SectionContainer>
			<ServicesPageBackgroundLow>
				<InfoBannerSectionDetailsContainer paddingBottom={2}>
					<IconHello />
				</InfoBannerSectionDetailsContainer>
				<InfoBannerSectionDetailsContainer paddingBottom={2}>
					<InfoBannerSectionTitle fontSize={2} fontColor={`orange`}>{`새로 오시는 분들을 위해 예배당 앞에서 새가족 팀이 안내를 도와드리고 있습니다.`}</InfoBannerSectionTitle>
				</InfoBannerSectionDetailsContainer>
				<InfoBannerSectionDetailsContainer paddingBottom={3}>
					<InfoBannerSectionDesc>
						{`문의가 있으시거나 미리 연락하시고 싶으신 분들은 연락처 페이지를 통해서 연락주세요.`}
					</InfoBannerSectionDesc>

				</InfoBannerSectionDetailsContainer>
				<ButtonsContainer>
					<TagLink fontColor={`orange`} href="/info/contact">
						<ButtonSecondary>
							{`문의하기`}
						</ButtonSecondary>
					</TagLink>
				</ButtonsContainer>
			</ServicesPageBackgroundLow>
		</>
	)
}

export default ServicesPage
